<template>

<div class="bg-default">
    <header class="header hidden">
        <div class="header-left">
            <a href="#">
                <img class="header-settings-icon" src="img/settings-icon.png" alt="Settings">
            </a>
        </div>
        <div class="header-center">
        </div>
        <div class="header-right">
            <a @click="exit()">
                <img class="header-settings-icon" src="img/exit-icon.png" alt="Exit">
            </a>
        </div>
    </header>
    <main class="main">
        <div class="container">
            <div class="card card-800">
                <div class="counter-wrapper">
                    <div class="counter">
                        <div class="counter-title">
                            Devizám
                        </div>
                        <div class="counter-numbers">
                            <!-- <span>2</span><span>2</span><span>0</span><span>4</span> -->
                            <Roller class="roller" :text="devizaData" :transition="3"></Roller>
                        </div>
                        <div class="counter-bar">
                            <div class="counter-bar-fill" :style="{'width': this.widthUp + '%'}"></div>
                        </div>
                    </div>
                    <div class="counter">
                        <div class="counter-title">
                            Értékpapírjaim összértéke
                        </div>
                        <div class="counter-numbers">
                            <!-- <span>5</span><span>3 </span><span>4</span><span>3</span><span>6</span> -->
                            <Roller :defaulChar="this.defaultSecurities"  :text="this.defaultSecurities" :transition="3"></Roller>
                        </div>
                        <div class="counter-bar">
                            <div class="counter-bar-fill" :style="{'width': this.widthDown + '%'}"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <footer class="footer">
    </footer>
</div>
</template>

<script>
import Roller from "vue-roller";
import { mapGetters } from 'vuex';

export default {
    computed: {
    ...mapGetters([
      "deviza",
      "securitiesAmountSum",
      "profit"
    ]),
  },
    created() {
        setTimeout(() => {
            this.devizaData = Math.round((this.deviza +this.securitiesAmountSum)).toString();
            this.$store.commit('setDeviza', this.devizaData);
            this.defaultSecurities = '0000';
        },3000)
            this.defaultSecurities = this.securitiesAmountSum.toString();
        this.initDeviza = this.deviza.toString();
        this.devizaData = this.deviza.toString();
    },
    data() {
        return {
            initNum: '4956',
            initDeviza: '0000',
            default: '0000',
            defaultSecurities: '0000',
            widthUp: 0,
            devizaData: '2000',
            widthDown: 100,
        };
    },
    components: {
        Roller,
    },
    mounted() {
        setTimeout(() => {
            this.progressBarUp();
            this.progressBarDown();

        },3000)
        setTimeout(() => {
            this.$store.dispatch("storeGameplayEvent", {
                round: 3,
                event: "action",
                profit: Math.round(this.profit * this.$config.game.prices.valutaPurchaseRate),
              });
            this.$router.push('result');
        },9000)
        /* setTimeout(() => {
            this.$router.push('rating')
        },5000) */
    },
    methods: {
        progressBarUp(){
            var self = this;
            var interUp = setInterval(() => {
                self.widthUp++;
                if(self.widthUp >= 100) {
                    clearInterval(interUp);
                }
            }, 30)
        },
        progressBarDown(){
            var self = this;
            var interDown = setInterval(() => {
                self.widthDown--;
                if(self.widthDown <= 0) {
                    clearInterval(interDown);
                }
            }, 30)
        }
    }
}
</script>

<style>
    .roller .rollerBlock {
		font-weight: 20px;
	}
</style>
